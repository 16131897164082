import styled from 'styled-components'
import { rgba } from 'polished'

import { Wrapper, Cover } from './pose'

export const StyledWrapper = styled(Wrapper)`
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  width: 100%;
  overflow: auto;
`

export const StyledCover = styled(Cover)`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: ${rgba('#000', 1 / 2)};
  cursor: pointer;
`
