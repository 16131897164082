import styled from 'styled-components'
import { fluidRange } from 'polished'

const min = { width: '320px', base: 16, contrast: 1.2 }
const max = { width: '1280px', base: 18, contrast: 1.6 }

export const MenuItem = styled.button.attrs({
  activeClassName: 'current-page',
  fontSize: 0,
})`
  display: block;
  height: 100%;
  margin: 0;
  padding: 0 1em;
  font-family: ${props => props.theme.fonts.sans};
  ${props =>
    fluidRange(
      {
        prop: 'fontSize',
        fromSize:
          props.fontSize > 1
            ? Math.pow(min.contrast, props.fontSize - 1) * min.base + 'px'
            : min.base * (props.fontSize > 0 ? 1 : 4 / 5) + 'px',
        toSize:
          props.fontSize > 1
            ? Math.pow(max.contrast, props.fontSize - 1) * max.base + 'px'
            : max.base * (props.fontSize > 0 ? 1 : 4 / 5) + 'px',
      },
      min.width,
      max.width
    )};
  line-height: 60px;
  text-decoration: none;
  color: ${props => props.theme.colors.text.reverse};
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  transition: color 125ms ease-out, box-shadow 125ms ease-out;
  &.current-page {
    color: ${props => props.theme.colors.brand.yellow};
    box-shadow: inset 0 0px 0 0 ${props => props.theme.colors.brand.yellow};
  }
  &&:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.brand.yellow};
    box-shadow: inset 0 -2.5px 0 0 ${props => props.theme.colors.brand.yellow};
  }
  &:focus {
    outline: none;
    color: ${props => props.theme.colors.brand.yellow};
    box-shadow: inset 0 -2.5px 0 0 ${props => props.theme.colors.brand.yellow};
  }
`
