import styled, { keyframes } from 'styled-components'

import { Box } from '../globals'

const fadeUpIn = keyframes`
  100% {
    opacity: 1;
    transform: translate3d(0,0,0);
  }
`

export const CardWrapper = styled(Box)`
  opacity: 0;
  transform: translate3d(0, 10px, 0);
  animation: ${fadeUpIn} 300ms ease-in-out forwards;
`
