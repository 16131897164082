import React from 'react'
import { ThemeProvider } from 'styled-components'

import theme from 'src/theme'
import { Footer, Header, Transition } from 'src/components'

import { GlobalStyles, Wrapper } from './style'
import './fonts.css'

const Layout = ({ location, children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyles />
      <Wrapper>
        <Header />
        <Transition location={location ? location : {}}>{children}</Transition>
        <Footer />
      </Wrapper>
    </>
  </ThemeProvider>
)

export default Layout
