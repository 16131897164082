import React from 'react'
import 'styled-components'
import { StaticQuery, graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import {
  Box,
  Button,
  H2,
  H3,
  DonateCard,
  FlexCol,
  FlexGrid,
} from 'src/components'

import serializer from 'src/utils/serializer'
import linkResolver from 'src/utils/linkResolver'

class DonationsBlock extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      activeDonationType: 'onetime',
      customDonation: '',
    }
  }

  setCustomDonation = event => {
    this.setState({ customDonation: event.target.value })
  }

  changeDonationType = type => {
    this.setState({ activeDonationType: type })
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          {
            donations: prismicDonations {
              dataString
              data {
                one_time_donations {
                  amount
                  color
                  description
                  donation_image {
                    fluid(maxWidth: 768) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
                monthly_donations {
                  amount
                  color
                  description
                  donation_image {
                    fluid(maxWidth: 768) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          let page = JSON.parse(data.donations.dataString)
          return (
            <Box maxWidth={1280} mx="auto">
              <Box mb={[3, 4]}>
                <Box width={1} maxWidth={640} mx="auto">
                  <H2
                    children={RichText.asText(page.donations_heading)}
                    mb="0.5em"
                    textAlign="center"
                  />
                  <Box mb={3} textAlign="center">
                    {RichText.render(
                      page.donations_description,
                      linkResolver,
                      serializer
                    )}
                  </Box>
                  <FlexGrid
                    justifyContent="center"
                    alignItems="center"
                    gutterX={[2]}
                    gutterY={[2]}
                  >
                    <Box>
                      <Button
                        children={`Single Donation`}
                        appearance={
                          this.state.activeDonationType === 'onetime'
                            ? 'yellow'
                            : ''
                        }
                        onClick={() => {
                          this.changeDonationType('onetime')
                        }}
                      />
                    </Box>
                    <Box>
                      <Button
                        children={`Monthly Donation`}
                        appearance={
                          this.state.activeDonationType === 'monthly'
                            ? 'yellow'
                            : ''
                        }
                        onClick={() => {
                          this.changeDonationType('monthly')
                        }}
                      />
                    </Box>
                  </FlexGrid>
                </Box>
              </Box>
              <Box mb={3} py={3} bg="bg.alt">
                <FlexGrid
                  justifyContent="center"
                  alignItems="center"
                  gutterX={[2]}
                  gutterY={[2]}
                >
                  <FlexCol alignItems="center" width={[1, 'auto']}>
                    <H3>
                      Make a{' '}
                      {this.state.activeDonationType === 'onetime'
                        ? 'single'
                        : 'monthly'}{' '}
                      donation
                    </H3>
                  </FlexCol>
                  <FlexCol alignItems="center" width={[1, 'auto']}>
                    <input
                      placeholder={`e.g. $50`}
                      type="number"
                      css={`
                        width: 128px;
                        padding: 16px;
                        border: 0;
                        font-family: ${props => props.theme.fonts.sans};
                        font-size: 16px;
                        background-color: #fff;
                        &::placeholder {
                          color: rgba(0, 0, 0, 0.5);
                          opacity: 1;
                        }
                        &:focus {
                          outline: 0;
                          box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.25);
                        }
                        &::-webkit-inner-spin-button,
                        &::-webkit-outer-spin-button {
                          -webkit-appearance: none;
                          margin: 0;
                        }
                      `}
                      value={this.state.customDonation}
                      onChange={this.setCustomDonation}
                    />
                  </FlexCol>
                  <FlexCol alignItems="center" width={[1, 'auto']}>
                    <Button
                      children={`Donate`}
                      appearance="yellow"
                      disabled={!this.state.customDonation}
                      as={'a'}
                      href={
                        (this.state.activeDonationType === 'onetime'
                          ? 'https://www.canadahelps.org/en/dn/14510?amount='
                          : 'https://www.canadahelps.org/en/dn/40069?amount=') +
                        this.state.customDonation
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                    />
                  </FlexCol>
                </FlexGrid>
              </Box>
              <Box>
                {this.state.activeDonationType === 'monthly' && (
                  <FlexGrid gutterX={[2, 3]} gutterY={[3]}>
                    {data.donations.data.monthly_donations.map(
                      (donation, index) => (
                        <Box width={[1, 1 / 3]}>
                          <DonateCard
                            amount={`$` + donation.amount + '/mo'}
                            impact={donation.description}
                            bg={'brand.' + donation.color.toLowerCase()}
                            key={'monthlyDonation' + index}
                            href={
                              'https://www.canadahelps.org/en/dn/40069?amount=' +
                              donation.amount
                            }
                            image={
                              donation.donation_image
                                ? donation.donation_image.fluid
                                : ''
                            }
                          />
                        </Box>
                      )
                    )}
                  </FlexGrid>
                )}
                {this.state.activeDonationType === 'onetime' && (
                  <FlexGrid gutterX={[2, 3]} gutterY={[3]}>
                    {data.donations.data.one_time_donations.map(
                      (donation, index) => (
                        <Box width={[1, 1 / 3]}>
                          <DonateCard
                            amount={`$` + donation.amount}
                            impact={donation.description}
                            bg={'brand.' + donation.color.toLowerCase()}
                            key={'monthlyDonation' + index}
                            href={
                              'https://www.canadahelps.org/en/dn/14510?amount=' +
                              donation.amount
                            }
                            image={donation.donation_image.fluid}
                          />
                        </Box>
                      )
                    )}
                  </FlexGrid>
                )}
              </Box>
            </Box>
          )
        }}
      />
    )
  }
}

export default DonationsBlock
