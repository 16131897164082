// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-campaign-index-js": () => import("./../../../src/pages/_campaign/_index.js" /* webpackChunkName: "component---src-pages-campaign-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-donate-index-js": () => import("./../../../src/pages/donate/index.js" /* webpackChunkName: "component---src-pages-donate-index-js" */),
  "component---src-pages-events-index-js": () => import("./../../../src/pages/events/index.js" /* webpackChunkName: "component---src-pages-events-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-impact-index-js": () => import("./../../../src/pages/our-impact/index.js" /* webpackChunkName: "component---src-pages-our-impact-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-why-ssubi-index-js": () => import("./../../../src/pages/why-ssubi/index.js" /* webpackChunkName: "component---src-pages-why-ssubi-index-js" */)
}

