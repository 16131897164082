import styled, { createGlobalStyle } from 'styled-components'
import { normalize } from 'polished'

export const GlobalStyles = createGlobalStyle`
  ${normalize()};
  *, *::before, *::after { box-sizing: border-box; }
  body {
    background: #fff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a { color: inherit; }
`

export const Wrapper = styled.div`
  overflow: hidden;
`
