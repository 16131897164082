import React from 'react'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import { color, space, textAlign, width } from 'styled-system'

const min = { width: '320px', base: 16, contrast: 1.2 }
const max = { width: '1280px', base: 18, contrast: 1.6 }

export const H1 = styled(
  ({
    width,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    bg,
    ...props
  }) => <h1 children={props.children} {...props} />
)`
  ${width};
  ${space};
  font-family: ${props => props.theme.fonts.sans};
  ${fluidRange(
    {
      prop: 'fontSize',
      fromSize: Math.pow(min.contrast, 3) * min.base + 'px',
      toSize: Math.pow(max.contrast, 3) * max.base + 'px',
    },
    min.width,
    max.width
  )};
  font-weight: 700;
  ${textAlign};
  letter-spacing: -0.025em;
  line-height: 1.2;
  ${color};
`

H1.defaultProps = {
  m: 0,
  color: 'text.default',
}

export const H2 = styled(
  ({
    width,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    bg,
    ...props
  }) => <h2 children={props.children} {...props} />
)`
  ${width};
  ${space};
  font-family: ${props => props.theme.fonts.sans};
  ${fluidRange(
    {
      prop: 'fontSize',
      fromSize: Math.pow(min.contrast, 2) * min.base + 'px',
      toSize: Math.pow(max.contrast, 2) * max.base + 'px',
    },
    min.width,
    max.width
  )};
  font-weight: 700;
  ${textAlign};
  letter-spacing: -0.025em;
  line-height: 1.2;
  ${color};
`

H2.defaultProps = {
  m: 0,
  color: 'text.default',
}

export const H3 = styled(
  ({
    width,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    bg,
    ...props
  }) => <h3 children={props.children} {...props} />
)`
  ${width};
  ${space};
  font-family: ${props => props.theme.fonts.sans};
  ${fluidRange(
    {
      prop: 'fontSize',
      fromSize: Math.pow(min.contrast, 1) * min.base + 'px',
      toSize: Math.pow(max.contrast, 1) * max.base + 'px',
    },
    min.width,
    max.width
  )};
  font-weight: 700;
  ${textAlign};
  letter-spacing: -0.025em;
  line-height: 1.2;
  ${color};
`

H3.defaultProps = {
  m: 0,
  color: 'text.default',
}

export const H6 = styled(
  ({
    width,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    bg,
    ...props
  }) => <h6 children={props.children} {...props} />
)`
  ${width};
  ${space};
  font-family: ${props => props.theme.fonts.sans};
  ${fluidRange(
    {
      prop: 'fontSize',
      fromSize: min.base * (3 / 4) + 'px',
      toSize: max.base * (3 / 4) + 'px',
    },
    min.width,
    max.width
  )};
  font-weight: 700;
  ${textAlign};
  text-transform: uppercase;
  letter-spacing: -0.0125em;
  line-height: 1.2;
  ${color};
`

H6.defaultProps = {
  m: 0,
  color: 'text.default',
}
