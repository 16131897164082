const colors = {
  text: {
    default: '#000',
    body: '#383838',
    reverse: '#fff',
  },
  bg: {
    default: '#fff',
    alt: '#E6E6E6',
    wash: '#f7f7f7',
    reverse: '#383838',
  },
  brand: {
    yellow: '#EAAA42',
    green: '#8EBA54',
    red: '#D65F36',
    teal: '#6BA2BA',
  },
}

const space = [0, 8, 16, 32, 64, 128, 256]

const fonts = {
  display: `'Better Times'`,
  sans: `'Nunito Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;`,
  serif: `Georgia, 'Times New Roman', Times, serif`,
  mono: `monospace`,
}

const theme = {
  colors,
  space,
  fonts,
}

export default theme
