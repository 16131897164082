import styled from 'styled-components'
import { cover } from 'polished'

import brushStroke01 from 'src/images/brushStrokes/bs01.png'
import brushStroke02 from 'src/images/brushStrokes/bs02.png'
import brushStroke03 from 'src/images/brushStrokes/bs03.png'

export const Wrapper = styled.span`
  position: relative;
  z-index: 0;
  &:after {
    content: '';
    ${cover()};
    z-index: -1;
    background-image: url(${props =>
      props.variant === 0
        ? brushStroke01
        : props.variant === 1
        ? brushStroke02
        : props.variant === 2
        ? brushStroke03
        : ''});
    background-repeat: no-repeat;
    background-size: 100% 75%;
    background-position: 0% 50%;
    user-select: none;
  }
`
