import React from 'react'

import { Text, Wrapper } from './style'

const Button = props => (
  <Wrapper appearance={props.appearance || 'default'} {...props}>
    <Text>{props.children}</Text>
  </Wrapper>
)

export default Button
