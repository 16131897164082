import React from 'react'
import Img from 'gatsby-image'
import { rgba, cover } from 'polished'

import { Box, H2, H6, Text } from 'src/components'

import { CardWrapper } from './style'

const DonateCard = props => (
  <CardWrapper bg={props.bg}>
    <Box
      as="a"
      target="_blank"
      rel="noopener noreferrer"
      href={props.href}
      position="relative"
      display="block"
      pb="calc(240/360 * 100%)"
      bg="bg.alt"
    >
      <Img fluid={props.image} style={{ ...cover(), userSelect: 'none' }} />
    </Box>
    <Box py={3} px={2}>
      <H2 children={props.amount} textAlign="center" color="text.reverse" />
      <Text children={props.impact} textAlign="center" color="text.reverse" />
    </Box>
    <Box
      as="a"
      target="_blank"
      rel="noopener noreferrer"
      href={props.href}
      display="block"
      py={2}
      px={2}
      bg={rgba('black', 0.1)}
      style={{ textDecoration: 'none' }}
    >
      <H6 children={`Donate`} textAlign="center" color="text.reverse" />
    </Box>
  </CardWrapper>
)

export default DonateCard
