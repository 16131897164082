import posed from 'react-pose'

export const Wrapper = posed.div({
  enter: {
    y: 0,
    delay: 75,
    transition: { duration: 125 },
  },
  exit: {
    y: '100%',
    transition: { duration: 125 },
  },
})

export const Cover = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0 },
})
