import React from 'react'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import { color, space, textAlign, width } from 'styled-system'

const min = { width: '320px', base: 16, contrast: 1.5 }
const max = { width: '2560px', base: 18, contrast: 1.75 }

const DisplayText = styled(
  ({
    width,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    textAlign,
    color,
    bg,
    ...props
  }) => <h1 children={props.children} {...props} style={{ maxWidth: 750 }} />
)`
  ${width};
  ${space};
  font-family: ${props => props.theme.fonts.display};
  ${fluidRange(
    {
      prop: 'fontSize',
      fromSize: Math.pow(min.contrast, 4) * min.base + 'px',
      toSize: Math.pow(max.contrast, 4) * max.base + 'px',
    },
    min.width,
    max.width
  )};
  font-weight: 400;
  ${textAlign};
  line-height: 0.667;
  ${color};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

DisplayText.defaultProps = {
  color: 'text.default',
  textAlign: 'center',
}

export default DisplayText
