import styled from 'styled-components'
import { fluidRange } from 'polished'
import { maxWidth, minWidth, space, width } from 'styled-system'

import DefaultStyle from './defaultStyle'
import AltStyle from './altStyle'
import YellowStyle from './yellowStyle'

export const Wrapper = styled.button`
  display: inline-block;
  ${width};
  ${minWidth};
  ${maxWidth};
  ${space};
  padding: 14px 32px;
  text-decoration: none;
  border: none;
  border-radius: 18px;
  user-select: none;
  appearance: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 2.5px;
  transition: box-shadow 250ms ease-out, transform 250ms ease-out;
  &:focus {
    outline: none;
  }
  @media not all and (hover: none) {
    &:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px;
    }
  }
  &:active {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 5px;
    transform: scale3d(0.99);
  }

  ${props => props.appearance === 'default' && DefaultStyle};
  ${props => props.appearance === 'alt' && AltStyle};
  ${props => props.appearance === 'yellow' && YellowStyle};
`

const min = { width: '320px', base: 16, contrast: 1.2 }
const max = { width: '960px', base: 18, contrast: 1.6 }

export const Text = styled.span`
  display: block;
  font-family: ${props => props.theme.fonts.sans};
  ${fluidRange(
    {
      prop: 'fontSize',
      fromSize: min.base * (3 / 4) + 'px',
      toSize: max.base * (3 / 4) + 'px',
    },
    min.width,
    max.width
  )};
  font-weight: 900;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.0125em;
  line-height: 1.2;
  color: inherit;
`
