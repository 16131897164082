import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'

import { Box, Button, FlexRow, Logo, MobileMenu } from 'src/components'

import { MenuItem } from './style'

const SocialIcon = props => (
  <Box width={30}>
    <Box
      as="a"
      href={props.href}
      target="_blank"
      rel="noopener noreferrer"
      display="block"
      width={1}
    >
      <Box position="relative" pb="100%">
        {props.symbol}
      </Box>
    </Box>
  </Box>
)

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        events: allPrismicEvent {
          edges {
            node {
              id
              dataString
            }
          }
        }
      }
    `}
    render={data => {
      let events = []
      data.events &&
        data.events.edges.forEach(event => {
          events.push(JSON.parse(event.node.dataString))
        })
      return (
        <Box height="60px" bg="bg.reverse">
          <FlexRow justifyContent="space-between">
            <Box>
              <Box as={Link} to="/" display="block" bg="brand.yellow">
                <Box px={2} py={1}>
                  <Logo height={40} color="#fff" />
                </Box>
              </Box>
            </Box>
            <FlexRow
              display={['none', 'none', null, 'flex']}
              alignItems="center"
            >
              <FlexRow mr={2}>
                <MenuItem children={`Why Ssubi?`} as={Link} to="/why-ssubi" />
                <MenuItem children={`Give Hope`} as={Link} to="/donate" />
                <MenuItem children={`Our Impact`} as={Link} to="/our-impact" />
                <MenuItem children={`Team`} as={Link} to="/team" />
                <MenuItem children={`Contact`} as={Link} to="/contact" />
              </FlexRow>
              <FlexRow mr={2} alignItems="center">
                {/* <Box mr={1}>
                  <Button
                    children={`Rhythm & Hope Ssubi 2023`}
                    as="a"
                    href="https://www.eventbrite.ca/e/rhythm-and-hope-2023-tickets-623007019767"
                    target="_blank"
                    appearance="yellow"
                    rel="noopener noreferrer"
                  />
                </Box> */}
                {events.map((event, index) => {
                  if (event.feature_in_header === 'Yes') {
                    return (
                      <Box mr={1}>
                        <Button
                          key={'headerEvent' + index}
                          children={event.event_title[0].text}
                          appearance="yellow"
                          as="a"
                          href={event.event_link}
                          target="_blank"
                          rel="noopener noreferrer"
                        />
                      </Box>
                    )
                  } else {
                    return null
                  }
                })}
                <Button
                  children={`Donate`}
                  as={Link}
                  to="/donate"
                  appearance="yellow"
                  mr={2}
                />
                <SocialIcon
                  href="https://www.facebook.com/SsubiFoundation/"
                  symbol={
                    <svg
                      width="100%"
                      viewBox="0 0 112.04 112.04"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <title>Facebook</title>
                      <circle
                        cx={56.02}
                        cy={56.02}
                        r={56.02}
                        transform="rotate(-.82 56.196 56.302)"
                        fill="#383838"
                      />
                      <path
                        d="M61 42.91V37.6c0-2.58 1.72-3.19 2.93-3.19h7.44V23H61.08c-11.38 0-14 8.48-14 13.91v6h-6.54v13.27h6.63v33.18h13.27V56.18h9.85l.48-5.18.73-8.06z"
                        fill="#fff"
                      />
                    </svg>
                  }
                />
                <SocialIcon
                  symbol={
                    <svg
                      width="100%"
                      viewBox="0 0 112.04 112.04"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <title>Twitter</title>
                      <path
                        d="M112 56A56 56 0 1 1 56 0a56 56 0 0 1 56 56z"
                        fill="#383838"
                      />
                      <path
                        d="M89.2 38.93A27.56 27.56 0 0 1 81.38 41a13.52 13.52 0 0 0 6-7.42 27.69 27.69 0 0 1-8.65 3.26 13.67 13.67 0 0 0-9.93-4.24 13.42 13.42 0 0 0-13.28 16.5 38.9 38.9 0 0 1-28.06-14 13.16 13.16 0 0 0-1.84 6.73A13.37 13.37 0 0 0 31.68 53a13.78 13.78 0 0 1-6.17-1.68v.17a13.13 13.13 0 0 0 .93 4.86 13.59 13.59 0 0 0 10 8.28 13.87 13.87 0 0 1-6.15.23A13.62 13.62 0 0 0 43 74.16a27.63 27.63 0 0 1-16.91 5.74 29.09 29.09 0 0 1-3.25-.19 39 39 0 0 0 20.87 6c21.28 0 34.36-14.75 37.81-30a36.86 36.86 0 0 0 .93-8.09v-1.73a27.31 27.31 0 0 0 6.75-6.96z"
                        fill="#fff"
                      />
                    </svg>
                  }
                  href="https://twitter.com/ssubifoundation/"
                />
                <SocialIcon
                  symbol={
                    <svg
                      width="100%"
                      viewBox="0 0 112.04 112.04"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <title>Instagram</title>
                      <path
                        d="M112 56A56 56 0 1 1 56 0a56 56 0 0 1 56 56z"
                        fill="#383838"
                      />
                      <path
                        d="M92.36 42.45a28 28 0 0 0-1.57-9.73A19 19 0 0 0 87.45 27a18.79 18.79 0 0 0-10.11-6 33.59 33.59 0 0 0-4.34-.68c-1-.11-1.93-.11-2.9-.16a.92.92 0 0 1-.28-.14H43.21a2.94 2.94 0 0 1-.64.14 26.62 26.62 0 0 0-9.88 1.73 18.41 18.41 0 0 0-4.92 2.91 18 18 0 0 0-4.66 5.75 18.87 18.87 0 0 0-2 6.35c-.22 1.84-.3 3.7-.45 5.55a.92.92 0 0 1-.14.28v26.58a6.27 6.27 0 0 1 .14.85 24.6 24.6 0 0 0 2.86 12 18.14 18.14 0 0 0 3.89 4.76 18.6 18.6 0 0 0 7.2 3.9 21.72 21.72 0 0 0 5.65 1c1.09 0 2.17.09 3.26.15a1 1 0 0 1 .29.14h25.26a5.14 5.14 0 0 1 .86-.14 28.43 28.43 0 0 0 9.47-1.44 19.09 19.09 0 0 0 6.26-3.62A19.35 19.35 0 0 0 91.75 76a28.8 28.8 0 0 0 .45-3.83c.1-1.06.11-2.13.16-3.19a1 1 0 0 1 .14-.29V43.31a5.31 5.31 0 0 1-.14-.86zM85.63 72A17 17 0 0 1 84 78.26 11.64 11.64 0 0 1 79.61 83a14.19 14.19 0 0 1-6.48 2l-4.13.39v.06H48.17c-2.58 0-5.16-.1-7.73-.3a15.79 15.79 0 0 1-6.37-1.71 11.93 11.93 0 0 1-6-7.58 28.51 28.51 0 0 1-.85-7.47c0-7.65-.16-15.31-.1-23a36 36 0 0 1 .82-8.83 12.16 12.16 0 0 1 9.86-9.29 34.68 34.68 0 0 1 6.67-.58c7.56 0 15.12-.16 22.68-.09a32.08 32.08 0 0 1 9.69 1.09 12.2 12.2 0 0 1 4.85 2.85 12.68 12.68 0 0 1 3.54 6.78 39.56 39.56 0 0 1 .58 7.53c0 7 .11 13.93.12 20.9-.01 2.1-.13 4.18-.3 6.25z"
                        fill="#fff"
                      />
                      <path
                        d="M57.16 37.46a18.56 18.56 0 1 0 17.89 19 18.5 18.5 0 0 0-17.89-19zm-1.51 30.43A11.88 11.88 0 0 1 44.56 56a11.9 11.9 0 1 1 23.8 0 11.89 11.89 0 0 1-12.71 11.89zM75.93 32.41c-2.58-.13-4.83 2.21-4.57 4.42a4.32 4.32 0 0 0 4.26 4.36 4.44 4.44 0 0 0 4.47-4.27 4.52 4.52 0 0 0-4.16-4.51z"
                        fill="#fff"
                      />
                    </svg>
                  }
                  href="https://www.instagram.com/ssubifoundation/"
                />
                <SocialIcon
                  symbol={
                    <svg
                      width="100%"
                      viewBox="0 0 112.04 112.04"
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}
                    >
                      <title>Vimeo</title>
                      <path
                        d="M112 56A56 56 0 1 1 56 0a56 56 0 0 1 56 56z"
                        fill="#383838"
                      />
                      <path
                        d="M91.63 40.74c-4.1 23.09-27 42.64-33.89 47.11s-13.18-1.8-15.45-6.52c-2.61-5.39-10.44-34.63-12.48-37.06s-8.19 2.43-8.19 2.43l-3-3.91s12.48-14.9 22-16.77c10.06-2 10 15.46 12.47 25.14 2.34 9.37 3.92 14.72 6 14.72s6-5.22 10.24-13.23-.18-15.08-8.57-10c3.31-20.16 34.97-25 30.87-1.91z"
                        fill="#fff"
                      />
                    </svg>
                  }
                  href="https://vimeo.com/user95512287"
                />
              </FlexRow>
            </FlexRow>
            <FlexRow
              display={['flex', 'flex', null, 'none']}
              alignItems="center"
              mr={1}
            >
              <MobileMenu events={events} />
            </FlexRow>
          </FlexRow>
        </Box>
      )
    }}
  />
)

export default Header
