import React from 'react'
import { Link } from 'gatsby'
import 'styled-components'
import Img from 'gatsby-image'
import { cover, rgba } from 'polished'

import { Box, FlexCol, FlexGrid } from '../globals'
import Button from '../button'
import { H3 } from '../headings'
import Text from '../text'

const GivingCard = ({ link, color, image, title, description }) => (
  <div
    css={`
      width: 100%;
    `}
  >
    <FlexGrid
      bg={'brand.' + color}
      css={`
        min-height: 320px;
      `}
      gutterX={[3]}
      gutterY={[3]}
    >
      <FlexCol width={[1, 1 / 3]}>
        <Box position="relative" height={['300px', '100%']} as={Link} to={link}>
          {image && (
            <Img
              fluid={image}
              style={{
                ...cover(),
                width: '100%',
                height: '100%',
                userSelect: 'none',
              }}
            />
          )}
        </Box>
      </FlexCol>
      <FlexCol width={[1, 2 / 3]} flex={1} p={3}>
        <H3 children={title} mb={2} color="text.reverse" />
        <Text color="text.reverse">{description}</Text>
        <div
          css={`
            margin-top: auto;
            padding-top: 32px;
          `}
          as={Link}
          to={link}
        >
          <Button
            children={`Learn more`}
            css={`
              color: #fff;
              background-color: ${rgba('#000', 0.25)};
            `}
          />
        </div>
      </FlexCol>
    </FlexGrid>
  </div>
)

export default GivingCard
