import React from 'react'

import { Box } from '../globals'

const Loader = ({ reverse }) => (
  <Box
    css={`
      .lds-ring {
        display: inline-block;
        position: relative;
        width: 64px;
        height: 64px;
      }
      .lds-ring div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 51px;
        height: 51px;
        margin: 6px;
        border: 6px solid ${reverse ? '#fff' : '#000'};
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${reverse ? '#fff' : '#000'} transparent transparent
          transparent;
      }
      .lds-ring div:nth-child(1) {
        animation-delay: -0.45s;
      }
      .lds-ring div:nth-child(2) {
        animation-delay: -0.3s;
      }
      .lds-ring div:nth-child(3) {
        animation-delay: -0.15s;
      }
      @keyframes lds-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    `}
  >
    <div class="lds-ring">
      <div />
      <div />
      <div />
      <div />
    </div>
  </Box>
)

export default Loader
