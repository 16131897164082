import React from 'react'
import styled from 'styled-components'
import { fluidRange } from 'polished'
import {
  color,
  fontFamily,
  fontWeight,
  lineHeight,
  space,
  textAlign,
  width,
} from 'styled-system'

const min = { width: '320px', base: 16, contrast: 1.2 }
const max = { width: '1280px', base: 18, contrast: 1.6 }

const Text = styled(
  ({
    width,
    m,
    mt,
    mr,
    mb,
    ml,
    mx,
    my,
    p,
    pt,
    pr,
    pb,
    pl,
    px,
    py,
    fontFamily,
    fontSize,
    fontWeight,
    textAlign,
    lineHeight,
    color,
    bg,
    ...props
  }) => <p {...props} />
)`
  ${width};
  ${space};
  ${fontFamily};
  ${props =>
    fluidRange(
      {
        prop: 'fontSize',
        fromSize:
          props.fontSize > 1
            ? Math.pow(min.contrast, props.fontSize - 1) * min.base + 'px'
            : min.base * (props.fontSize > 0 ? 1 : 4 / 5) + 'px',
        toSize:
          props.fontSize > 1
            ? Math.pow(max.contrast, props.fontSize - 1) * max.base + 'px'
            : max.base * (props.fontSize > 0 ? 1 : 4 / 5) + 'px',
      },
      min.width,
      max.width
    )};
  ${fontWeight};
  ${textAlign};
  letter-spacing: -0.005em;
  ${lineHeight};
  ${color};
`

Text.defaultProps = {
  m: 0,
  fontFamily: 'sans',
  fontSize: 1,
  fontWeight: 400,
  lineHeight: 1.5,
  color: 'text.default',
}

export default Text
