import React from 'react'
import { PoseGroup } from 'react-pose'

import { StyledWrapper, StyledCover } from './style'

class Panel extends React.Component {
  render() {
    return (
      <PoseGroup>
        {this.props.open && [
          // If animating more than one child, each needs a `key`
          <StyledCover key="cover" onClick={this.props.close} />,
          <StyledWrapper key="wrapper">{this.props.children}</StyledWrapper>,
        ]}
      </PoseGroup>
    )
  }
}

export default Panel
