import React from 'react'
import { Link } from 'gatsby'

import { Box, Button, FlexRow, Panel, Span, Text } from 'src/components'

const MenuItem = ({ linkTo, children, ...props }) => (
  <FlexRow
    as={Link}
    to={linkTo}
    justifyContent="center"
    alignItems="center"
    minHeight="50px"
    style={{ textDecoration: 'none' }}
    {...props}
  >
    <Text fontWeight={700}>
      <Span children={children} />
    </Text>
  </FlexRow>
)

class MobileMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
    }
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }

  render() {
    return (
      <>
        <Button children={`Menu`} onClick={this.toggleMenu} />
        <Panel open={this.state.showMenu} close={this.toggleMenu}>
          <Box pt={2} bg="bg.default">
            <MenuItem
              children={`Why Ssubi?`}
              linkTo="/why-ssubi"
              onClick={this.toggleMenu}
            />
            <MenuItem
              children={`Give Hope`}
              linkTo="/donate"
              onClick={this.toggleMenu}
            />
            <MenuItem
              children={`Our Impact`}
              linkTo="/our-impact"
              onClick={this.toggleMenu}
            />
            <MenuItem
              children={`Team`}
              linkTo="/team"
              onClick={this.toggleMenu}
            />
            <MenuItem
              children={`Contact`}
              linkTo="/contact"
              onClick={this.toggleMenu}
            />
            {this.props.events.map((event, index) => {
              if (event.feature_in_header === 'Yes') {
                return (
                  <MenuItem
                    key={'headerEvent' + index}
                    children={event.event_title[0].text}
                    bg="brand.yellow"
                    as="a"
                    href={event.event_link}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.toggleMenu}
                  />
                )
              } else {
                return null
              }
            })}
            <MenuItem
              children={`Donate`}
              linkTo="/donate"
              onClick={this.toggleMenu}
              pb="env(safe-area-inset-bottom)"
              bg="brand.yellow"
            />
          </Box>
        </Panel>
      </>
    )
  }
}

export default MobileMenu
